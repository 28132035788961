<template>
    <div>
        <div class="container boardcontainer">
            <div class="jumbotron text-center board" style="background: #ffffff">
                <h2 class="title">{{locationName}}</h2>
                <h2>親愛的客戶您好</h2>
                <h2>為配合政府防疫措施</h2>
                <h2>請掃描以下QR CODE</h2>
                <h2>完成實聯制登記</h2>
                <h2>填寫完畢請出示證明</h2>
                <h2 class="my-5"><qrcode-vue :value="url" :size="size" level="H" /></h2>
                <div class="noneed">網頁操作免安裝APP</div>
                <div class="noneed">不需重複填寫個人資料</div>
                <div class="noneed mb-5">資料只保留28天，屆期自動銷毀</div>
                <h2>{{locationName}}</h2>
                <h2>與您共同防疫並感謝您的配合</h2>
                <h2 class="power">power by <span><img src="/images/img_logo.png"></span></h2>
            </div>
        </div>
    </div>
</template>

<script>
    import QrcodeVue from 'qrcode.vue'
    import axios from "axios";

    export default {
        name: "StoreBoard",
        components: {
            QrcodeVue,
        },
        created() {
            this.locationSid = this.$route.params.locationSid
            this.url = 'https://speedpass.ysct.app/UserTracking/' + this.$route.params.locationSid
            this.initialLoad(this.$route.params.locationSid)
        },
        data: function() {
            return {
                locationName: '',
                locationSid: '',
                size: 250,
                url: ''
            }
        },
        methods: {
            initialLoad: function(locationSid) {
                axios.post('https://jj7lnyhmk0.execute-api.ap-southeast-1.amazonaws.com/TEST/initialquery',
                    { fingerPrint: '', locationSid: locationSid })
                    .then((res) => {
                        const result = JSON.parse(res.data.body)
                        if(result.MESSAGE === 'SUCCESS') {
                            this.locationName = result.LOCATION_NAME
                        }
                    })
                    .catch((error) => { console.error(error) })
            }
        }
    }
</script>

<style scoped>

</style>
